import React, { useState } from "react";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";
import { DefaultLayout } from "../layouts";
import { white, black } from "../utils";
import { Header, FlipCard, FlipCardInner, FlipCardBack, FlipCardFront, FlipCardPadder, Background, PageWrapper } from "../components";

const Boxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const Group = styled.div`
  color: white;
  margin: 10px;
  text-align: right;
  font-size: 20pt;
  font-family: 'roboto';
  transition: all 0.5s;
`;

const Template = styled.div`
  color: white;
  padding: 5px;
  font-size: 11pt;
  font-family: 'roboto';
  text-decoration: none;
  text-align: left;
  transition: all 0.5s;
  cursor: pointer;
  & :hover {
    background-color: ${white};
    opacity: 0.8;
    color: ${black};
  }
`;

const DefaultText = styled.s`
  color: white;
  padding: 5px;
  font-size: 11pt;
  font-family: 'roboto';
  text-decoration: none;
  text-align: left;
  transition: all 0.5s;
  & :hover {
    background-color: ${white};
    opacity: 0.8;
    color: ${black};
    a {
      color: ${black};

    }
  }
  a {
    color: white;
    font-size: 11pt;
  }
`;
const TemplatesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const FundsPage = ({ data }) => {
  let funds = data.funds;

  function templateClicked(template) {
    navigate(`/funds/${template}`);
  }

  function FundGrouping({ group, backdrops}) {
    let backdrop = backdrops.edges
      .find(b => b.node.name === group.backdrop);
    let publicURL = backdrop != null ? backdrop.node.publicURL : '';
    return (<FlipCard>
      <FlipCardInner image={publicURL}>
        <FlipCardPadder></FlipCardPadder>
        <FlipCardFront>
          <Group>
            {group.name}
          </Group>
        </FlipCardFront>
        <FlipCardBack>
          <TemplatesList>
            {group.templates.length > 0 
            ? group.templates.map((template, tempIndex) => (
              <Template key={tempIndex} onClick={() => templateClicked(template.value)}>{template.name}</Template>
            ))
          : <DefaultText>For further information on Stratton Street PCC Limited – Renminbi Bond Fund and Next Generation Global Bond Fund please contact <a href="mailto:funds@strattonstreet.com">funds@strattonstreet.com</a>.</DefaultText>}
          </TemplatesList>
        </FlipCardBack>
      </FlipCardInner>
    </FlipCard>);
  }

  return (
    <DefaultLayout title="Products">
      <Background image={data.logo.publicURL}>
        <Header color={white}>Our Products</Header>
      </Background>
      <PageWrapper>
        <Boxes>
          {funds.groups.map((group, index) => (
            <FundGrouping key={index} group={group} backdrops={data.backdrops}></FundGrouping>
          ))}
        </Boxes>
      </PageWrapper>
    </DefaultLayout>
  )
};

export const query = graphql`
  {
    funds: fundsJson {
      groups {
        name
        backdrop
        templates {
          value
          name
        }
      }
    }
    logo: file(name: { eq: "our-funds" }) {
      publicURL
    }
    backdrops: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default FundsPage;
